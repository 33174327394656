

import React from 'react';
import Section from "../../components/ui/Section/Section";
import Layout from '../../components/Layout';
import Hero from '../../components/ui/Hero/Hero';
import { Link } from 'gatsby';
import Illustration from '../../assets/karriere.svg';
import * as styles from './job-karriere.module.scss';
import classnames from 'classnames';
import HorizontalScroll from '../../components/ui/HorizontalScroll/HorizontalScroll';
import Card from '../../components/ui/Card/Card';
import ButtonLink from '../../components/ui/Buttons/ButtonLink';
import Leistungen1 from "../../assets/materialien.svg";

export default function JobsKarriere () {

    return <Layout crumbs={[{ label: "Home", url: "" }, { label: "Unternehmen", url: "unternehmen" }, { label: "Karriere", url: "jobs-karriere" }]} headerColor="dark" hasWave={false} breadcrumbColor="light">
        <Hero
                color="dark"
                title="Bei uns arbeiten"
                subtitle="Modernes,  zukunftsorientiertes Unternehmen sucht großartige Talente"
                hasWaveBottom={["mobile", "tablet"]}
            >

        </Hero>


        <Section>
        <h3>Offene Positionen</h3>

        <HorizontalScroll>
                    <div className="columns has-flick-columns-tablet">
                        {/* <div className="column">
                            <Card to="/unternehmen/jobs-karriere/buerokraft">
                                <div className="content">
                                    <h3>Kauffrau / Kaufmann für Büromanagement (m/w/d)</h3>
                                    <ButtonLink to="/unternehmen/jobs-karriere/buerokraft">Mehr <span className="is-hidden-touch"> Informationen</span></ButtonLink>
                                </div>
                            </Card>
                        </div> */}
                        <div className="column">
                            <Card to="/unternehmen/jobs-karriere/bueroazubi">
                                <div className="content"><h5>Ausbildung</h5>
                                    <h3>Kauffrau / Kaufmann für Büromanagement (m/w/d)</h3>
                                    <ButtonLink to="/unternehmen/jobs-karriere/bueroazubi">Mehr <span className="is-hidden-touch"> Informationen</span></ButtonLink>
                                </div>
                            </Card>
                        </div>
                        {/* <div className="column">
                            <Card to="/unternehmen/jobs-karriere/mediengestalter">
                                <div className="content">
                                    <h3>Mediengestalter/in (m/w/d)</h3>
                                    <ButtonLink to="/unternehmen/jobs-karriere/mediengestalter">Mehr <span className="is-hidden-touch"> Informationen</span></ButtonLink>
                                </div>
                            </Card>
                        </div> */}
                        
                    </div>
                </HorizontalScroll>
                    <br />
                    <br />
                <p>
                    <h3>Nichts passendes gefunden?</h3>
                    <h5>Wir freuen uns sehr über Initiativbewerbung an: <a href="mailto:bewerbung@hisprint.de">bewerbung@hisprint.de</a></h5>
                 </p>

                <div className="content has-padding">
                    <h3>Lerne uns schon mal genauer kennen:</h3>
                    <ul>
                        <li><Link to="/produkte">Unsere alltäglichen Produkte</Link></li>
                        <li><Link to="/fallbeispiele">Fallbeispiele von Einzelprojekten</Link></li>
                        <li><Link to="/unternehmen">Lerne unser Team jetzt schon kennen</Link></li>
                    </ul>
                 </div>
        </Section>
    </Layout>
}